@font-face {
  font-family: "Inter";
  src: local("Inter"), url(./assets/font/Inter-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Inter";
  font-weight: 100;
  src: local("Inter"), url(./assets/font/Inter-Thin.ttf) format("truetype");
}

@font-face {
  font-family: "Inter";
  font-weight: 200;
  src: local("Inter"),
    url(./assets/font/Inter-ExtraLight.ttf) format("truetype");
}

@font-face {
  font-family: "Inter";
  font-weight: 300;
  src: local("Inter"), url(./assets/font/Inter-Light.ttf) format("truetype");
}

@font-face {
  font-family: "Inter";
  font-weight: 500;
  src: local("Inter"), url(./assets/font/Inter-Medium.ttf) format("truetype");
}

@font-face {
  font-family: "Inter";
  font-weight: 600;
  src: local("Inter"), url(./assets/font/Inter-SemiBold.ttf) format("truetype");
}

@font-face {
  font-family: "Inter";
  font-weight: 700;
  src: local("Inter"), url(./assets/font/Inter-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "Inter";
  font-weight: 800;
  src: local("Inter"), url(./assets/font/Inter-ExtraBold.ttf) format("truetype");
}

@font-face {
  font-family: "Inter";
  font-weight: 900;
  src: local("Inter"), url(./assets/font/Inter-Black.ttf) format("truetype");
}
